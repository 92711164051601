import styled, { css } from 'styled-components';
import { Badge } from 'reactstrap';
import tinycolor from 'tinycolor2';
import { darken } from 'polished';

export const BadgeStyled = styled(Badge)`
  border-radius: 24px;
  padding: 4px 8px;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  align-items: center;
  display: inline-flex;
  height: fit-content;
  width: fit-content;
  color: white;
  background: ${({ theme, color }) => theme.colors[color]};
  gap: 5px;

  ${({ color, theme }) =>
    !!color &&
    tinycolor(theme.colors[color]).isLight() &&
    css`
      color: ${darken(0.55, theme.colors[color])};
      background: ${theme.colors[color]};

      svg {
        color: ${darken(0.55, theme.colors[color])};
      }
    `}

  ${({ bordercolor, theme }) =>
    bordercolor &&
    css`
      border: 1px solid ${theme.colors[bordercolor]};
    `};
`;
