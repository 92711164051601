import React, { useEffect, useState } from 'react';
import { useDebounce } from '@kiper/hooks';
import propTypes from 'prop-types';

import StyledSelect from './styles';

const Select = React.forwardRef(
  (
    {
      onChange,
      value,
      inputValue,
      onInputChange,
      delay,
      name,
      options,
      clearOnSelected,
      ...props
    },
    ref,
  ) => {
    const debounce = useDebounce({ delay });
    const [inputData, setInputData] = useState(inputValue);
    const [selectData, setSelectData] = useState(value);

    useEffect(() => setSelectData(value), [value]);

    useEffect(() => setInputData(inputValue), [inputValue]);

    const handleChange = data => {
      if (!clearOnSelected) setSelectData(data);
      if (onChange) onChange(data);
    };

    const handleChangeInput = data => {
      setInputData(data);
      if (onInputChange) debounce(() => onInputChange(data));
    };

    return (
      <StyledSelect
        {...props}
        options={options}
        inputValue={inputData}
        onInputChange={handleChangeInput}
        value={selectData}
        onChange={handleChange}
        classNamePrefix="select"
        ref={ref}
      />
    );
  },
);
export default Select;

Select.displayName = 'Select';

Select.propTypes = {
  value: propTypes.oneOfType([
    propTypes.any,
    propTypes.object,
    propTypes.array,
  ]),
  onChange: propTypes.func,
  inputValue: propTypes.string,
  onInputChange: propTypes.func,
  delay: propTypes.number,
  options: propTypes.array,
  name: propTypes.string,
  clearOnSelected: propTypes.bool,
};

Select.defaultProps = {
  value: null,
  onChange: undefined,
  inputValue: '',
  name: '',
  options: [],
  onInputChange: undefined,
  delay: 0,
  clearOnSelected: false,
};
