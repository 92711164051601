import gql from 'graphql-tag';

export const createFloor = gql`
  mutation floorCreate($floor: FloorInput!) {
    floorCreate(floor: $floor)
  }
`;

export const deleteFloor = gql`
  mutation floorDelete($floorId: ID!) {
    floorDelete(floorId: $floorId)
  }
`;

export const updateFloor = gql`
  mutation floorUpdate($floorId: ID!, $floor: FloorInput!) {
    floorUpdate(floorId: $floorId, floor: $floor)
  }
`;
