import gql from 'graphql-tag';

export const floorUnityGroupFragment = gql`
  fragment floorUnityGroupParts on FloorUnityGroup {
    id
    description
    treeNodeId
    serializedParams
  }
`;

export const floorUnityFragment = gql`
  fragment floorUnityParts on FloorUnity {
    id
    description
    treeNodeId
  }
`;

export const floorFragment = gql`
  fragment floorFragmentParts on Floor {
    id
    floor
    unityGroup {
      ...floorUnityGroupParts
    }
  }
  ${floorUnityGroupFragment}
`;

export const floorByIdFragment = gql`
  fragment floorByIdParts on FloorById {
    id
    floor
    unityGroup {
      ...floorUnityGroupParts
    }
    unities {
      ...floorUnityParts
    }
  }
  ${floorUnityGroupFragment}
  ${floorUnityFragment}
`;
