import gql from 'graphql-tag';
import {
  floorFragment,
  floorUnityFragment,
  floorByIdFragment,
} from './fragments';

export const getFloors = gql`
  query floors($condominiumPersonContextId: ID!) {
    floors(condominiumPersonContextId: $condominiumPersonContextId) {
      ...floorFragmentParts
    }
  }
  ${floorFragment}
`;

export const getFloorUnities = gql`
  query floorUnities($floorId: ID!) {
    floorUnities(floorId: $floorId) {
      ...floorUnityParts
    }
  }
  ${floorUnityFragment}
`;

export const getFloorById = gql`
  query floorById($floorId: ID!) {
    floorById(floorId: $floorId) {
      ...floorByIdParts
    }
  }
  ${floorByIdFragment}
`;

export const getAccessGroups = gql`
  query accessGroups($deviceId: ID!) {
    accessGroups(deviceId: $deviceId)
  }
`;
