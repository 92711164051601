import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  from {
    background-position: -400px 0;
  }

  to {
    background-position: 400px 0;
  }
`;

export const PlaceholderLine = styled.div`
  width: 100%;

  .line {
    position: relative;
    width: 100%;

    & {
      height: 20px;
      background-image: linear-gradient(90deg, #f7f7f7, #eee, #f7f7f7);
      animation: ${shimmer} 1.2s ease-in-out infinite;
    }

    + .line {
      margin-top: 5px;
      width: 100%;
    }
  }

  // align centered paragraph
  &.aligned-centered {
    display: flex;
    flex-direction: column;
    align-items: center;

    .line {
      &::before {
        margin-left: 50%;
        left: -42%;
      }

      &::after {
        margin-left: 50%;
        left: -32%;
      }

      + .line {
        margin-top: 32px;

        &::before {
          left: -44%;
        }
        &::after {
          left: -18%;
        }
      }
    }
  }

  &.aligned-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .line {
      &::before,
      &::after {
        left: auto;
        right: 0;
      }

      + .line {
        margin-top: 32px;
      }
    }
  }
`;

export const PlaceholderParagraph = styled.div`
  .line {
    position: relative;
    width: 87%;
    margin: 20px 0;

    &,
    &::before,
    &::after {
      background-image: linear-gradient(90deg, #f7f7f7, #eee, #f7f7f7);
      animation: ${shimmer} 1.2s ease-in-out infinite;
      background-size: 800px 10px;
      height: 8px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
    }

    &::before {
      top: -20px;
      width: 84%;
    }

    &::after {
      bottom: -20px;
      width: 64%;
    }

    + .line {
      margin-top: 52px;
      width: 93%;

      &::before {
        width: 88%;
      }
      &::after {
        width: 36%;
      }
    }
  }
`;

export const PlaceholderMedia = styled.div`
  .line {
    position: relative;
    margin-top: 8px;
    margin-bottom: 25px;
    margin-left: 70px;
    height: 16px;

    &::before {
      content: '';
      position: absolute;
      top: -11px;
      left: -70px;
      width: 55px;
      height: 55px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 65%;
      height: 8px;
    }

    &,
    &::before,
    &::after {
      background-image: linear-gradient(90deg, #f7f7f7, #eee, #f7f7f7);
      animation: ${shimmer} 1.2s ease-in-out infinite;
      background-size: 800px 10px;
    }
  }
`;
