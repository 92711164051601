import gql from 'graphql-tag';

export const getGuidedAttendanceSettings = gql`
  query guidedAttendanceSettings {
    guidedAttendanceSettings {
      guidedAttendanceSettings {
        eventType
        condominium {
          id
          name
        }
      }
    }
  }
`;

export const getGuidedAttendanceAttendants = gql`
  query guidedAttendanceAttendants($filters: GuidedAttendanceFilters!) {
    guidedAttendanceAttendants(filters: $filters) {
      collection {
        eventType
        attendanceStepId
        kindOfAttendant {
          id
          name
          personId
          profileId
        }
      }
      pagination {
        page
        pageSize
        hasNextPage
      }
    }
  }
`;

export const getGuidedAttendancePartners = gql`
  query guidedAttendancePartners($filters: GuidedAttendanceFilters!) {
    guidedAttendancePartners(filters: $filters) {
      collection {
        eventType
        attendanceStepId
        partner {
          id
          name
          personContextId
        }
      }
      pagination {
        page
        pageSize
        hasNextPage
      }
    }
  }
`;
