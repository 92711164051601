import { useEffect, useMemo, useState } from 'react';

export const useResizeQrCode = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const qrCodeSize = useMemo(() => {
    if (windowWidth < 316) {
      return windowWidth - 28;
    }
    if (windowWidth <= 480) {
      return windowWidth - 54;
    }
    return 480 - 54;
  }, [windowWidth]);

  return { qrCodeSize };
};
