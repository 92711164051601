import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Radio = forwardRef(
  ({ label, name, checked, onChange, ...props }, ref) => {
    return (
      <S.RadioWrapper>
        <S.RadioInput
          type="radio"
          id={name}
          ref={ref}
          name={name}
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <S.RadioLabel htmlFor={name}>{label}</S.RadioLabel>
      </S.RadioWrapper>
    );
  },
);

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Radio.displayName = 'Radio';

export default Radio;
