import { useQuery as useQueryParams } from '@kiper/hooks';
import { GenericLoader } from '@kiper/ui';
import { MainInvite } from './MainInvite';
import { PorterSummitEditionInvite } from './PorterSummitEditionInvite';
import { InviteGuestProvider } from '../../../store/InviteGuest';
import { useFetchInviteGuest } from './hooks';
import useRemoteConfig from '../../../hooks/useRemoteConfig';

export default function InviteGuest() {
  const querystring = useQueryParams();
  const guestHash = querystring?.get('q')?.replace(' ', '+') || '';

  const inviteGuestQueryData = useFetchInviteGuest({ guestHash });

  const { condominiumPersonContextId } =
    inviteGuestQueryData?.data?.getInviteGuest || {};

  const specialInvitePorterSummit = useRemoteConfig({
    path: 'inviteGuest.specialInvitePorterSummit',
    condominium: Number(condominiumPersonContextId),
  });

  const inviteModel = () => {
    if (specialInvitePorterSummit) return 'porter-summit';
    return 'default';
  };

  const RenderInvite = {
    default: <MainInvite />,
    'porter-summit': <PorterSummitEditionInvite />,
  };

  if (
    inviteGuestQueryData?.loading ||
    inviteGuestQueryData?.error ||
    !guestHash
  )
    return <GenericLoader />;

  return (
    <InviteGuestProvider
      inviteGuestQueryData={inviteGuestQueryData}
      guestHash={guestHash}
    >
      {RenderInvite[inviteModel()]}
    </InviteGuestProvider>
  );
}
