import gql from 'graphql-tag';

export const syncFacialDevice = gql`
  mutation syncFacialDevice($deviceId: Int!) {
    syncFacialDevice(deviceId: $deviceId)
  }
`;

export const forceDeviceConnection = gql`
  mutation forceDeviceConnection($deviceId: Int!) {
    forceDeviceConnection(deviceId: $deviceId)
  }
`;

export const checkDeviceExist = gql`
  mutation checkDeviceExist($deviceId: String!, $type: String!) {
    checkDeviceExist(deviceId: $deviceId, type: $type)
  }
`;

export const deviceParamsConfig = gql`
  mutation deviceParamsConfig($deviceId: ID!, $data: [DeviceFormParamsInput]!) {
    deviceParamsConfig(deviceId: $deviceId, data: $data)
  }
`;

export const createIntercom = gql`
  mutation createIntercom($device: IntercomDeviceInput!) {
    createIntercom(device: $device)
  }
`;

export const editIntercom = gql`
  mutation editIntercom($device: IntercomDeviceInput!) {
    editIntercom(device: $device)
  }
`;

export const syncMultiBuildingConfigurations = gql`
  mutation syncMultiBuildingConfigurations(
    $deviceId: ID!
    $configOption: String!
  ) {
    syncMultiBuildingConfigurations(
      deviceId: $deviceId
      configOption: $configOption
    )
  }
`;
