import styled from 'styled-components';
import { Input } from 'reactstrap';

export default styled(Input).attrs({ type: 'checkbox' })`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 4px;
  position: relative;
  margin: 0;

  border: solid 1px ${props => props.theme.colors.secondary400};
  border-radius: 2px;
  background-color: white;
  accent-color: ${props => props.theme.colors.primary500};

  &:checked {
    background-color: ${props => props.theme.colors.primary500};
    border: solid 1px ${props => props.theme.colors.primary500};
    
    &:after {
      content: '';
      position: absolute;
      display: block;
      left: 6px;
      top: 2px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:disabled {
    background-color: ${props => props.theme.colors.secondary100};
    border-color: ${props => props.theme.colors.secondary100};
    cursor: not-allowed;
  }

  /* border: solid 1px ${props => props.theme.colors.secondary400};
  padding: 2px;
  color: ${props => props.theme.colors.mediumBlack}; */

  &:focus {
    border: solid 1px ${props => props.theme.colors.secondary400};
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
