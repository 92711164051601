import styled from 'styled-components';

export const RadioWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
`;

export const RadioInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.secondary400};
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  &:checked::before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primary500};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const RadioLabel = styled.label`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary800};
`;
