const generateUniqueId = () => {
  try {
    return crypto.randomUUID();
  } catch (e) {
    // Fallback para browsers que não suportam randomUUID
    return (
      Date.now().toString(36) +
      Math.random()
        .toString(36)
        .substring(2)
    );
  }
};

export default generateUniqueId;
