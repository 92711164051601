import styled from 'styled-components';

export const CanvasQrCodeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const MarginQrCode = styled.canvas`
  border-radius: 8px 8px 0 0;
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  z-index: 0;
`;
