import gql from 'graphql-tag';
import { exportationFragment, exportationItemFragment } from './fragments';

export const exportations = gql`
  query exportations($condominiumId: Int!, $filters: ExportationsQueryInput!) {
    exportations(condominiumId: $condominiumId, filters: $filters) {
      collection {
        ...exportationParts
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
  ${exportationFragment}
`;

export const exportationItems = gql`
  query exportationItems($exportationId: Int!) {
    exportationItems(exportationId: $exportationId) {
      ...exportationItemParts
    }
  }
  ${exportationItemFragment}
`;
