import { useRef, useEffect } from 'react';
import QrCodeLib from 'qrcode.react';
import propTypes from 'prop-types';
import theme from '@kiper/theme';
import * as S from './styles';

const QrCode = ({ value, size, marginSize }) => {
  const canvasRef = useRef(null);

  const draw = ctx => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = '#fff';
    ctx.beginPath();
    ctx.fill();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const render = () => {
      draw(context);
    };
    render();
  }, [draw]);

  return (
    <S.CanvasQrCodeContainer>
      <S.MarginQrCode
        width={marginSize * 2 + size}
        height={marginSize * 2 + size}
        ref={canvasRef}
      />
      <QrCodeLib
        style={{ zIndex: 1 }}
        value={value}
        size={size}
        fgColor={theme.colors.black}
        bgColor={theme.colors.white}
      />
    </S.CanvasQrCodeContainer>
  );
};

QrCode.propTypes = {
  value: propTypes.string.isRequired,
  size: propTypes.number.isRequired,
  marginSize: propTypes.number.isRequired,
};

export default QrCode;
