import gql from 'graphql-tag';

export const enableCondominium = gql`
  mutation guidedAttendanceEnableCondominium(
    $guidedAttendanceSettings: GuidedAttendanceSettingsInput!
  ) {
    guidedAttendanceEnableCondominium(
      guidedAttendanceSettings: $guidedAttendanceSettings
    )
  }
`;

export const disableCondominium = gql`
  mutation guidedAttendanceDisableCondominium(
    $guidedAttendanceSettings: GuidedAttendanceSettingsInput!
  ) {
    guidedAttendanceDisableCondominium(
      guidedAttendanceSettings: $guidedAttendanceSettings
    )
  }
`;

export const createGuidedAttendanceAttendant = gql`
  mutation createGuidedAttendanceAttendant(
    $body: GuidedAttendanceAttendantInput!
  ) {
    createGuidedAttendanceAttendant(body: $body)
  }
`;

export const deleteGuidedAttendanceAttendant = gql`
  mutation deleteGuidedAttendanceAttendant(
    $attendantPersonContextId: Int!
    $eventType: Int!
  ) {
    deleteGuidedAttendanceAttendant(
      attendantPersonContextId: $attendantPersonContextId
      eventType: $eventType
    )
  }
`;

export const createGuidedAttendancePartner = gql`
  mutation createGuidedAttendancePartner($body: GuidedAttendancePartnerInput!) {
    createGuidedAttendancePartner(body: $body)
  }
`;

export const deleteGuidedAttendancePartner = gql`
  mutation deleteGuidedAttendancePartner(
    $partnerPersonContextId: Int!
    $eventType: Int!
  ) {
    deleteGuidedAttendancePartner(
      partnerPersonContextId: $partnerPersonContextId
      eventType: $eventType
    )
  }
`;
